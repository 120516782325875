<template>
  <div class="car-confirmation">
    <Header :isHeader="true" :back="true" :step="currentStepIndex" />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar />
      </template>
      <Title titleStyle="title" :text="translateString('identifyVehicle')" />
      <CarInfo :justMobile="true" />
      <div
        class="checkbox-container"
        v-if="this.$store.state.carByPlate.ktypes != null"
      >
        <CheckboxContainer
          v-for="(ktype, index) in carKtypesInfo"
          :key="ktype.value"
          :id="'ktype-' + ktype.value"
          :label="ktype.label"
          :value="ktype.value"
          checkboxClass="checkbox-round"
          containerClass="right"
          inputType="radio"
          name="ktype"
          @updateInput="updateInput($event, index)"
        />
      </div>
      <p v-if="carCombo.comboSelectedByUser" class="sm-text-italic">
        {{ carCombo.comboLabel }}
      </p>
      <div class="row flex-wrap align-btn-mobile align-container-btn mb-2">
        <Button
          class="btn-tertiary elm-mobile"
          :label="translateString('changePlate')"
          @action="changePlate()"
        />
      </div>
      <template v-slot:footer>
        <Footer>
          <Button
            id="getCorrectToken"
            class="btn-primary align-btn-tertiary"
            :label="translateString('continue')"
            @action="next('getCorrectToken')"
            :disabled="!anyCheckboxSelected"
          />
          <Button
            class="btn-secondary elm-desktop"
            :label="translateString('changePlate')"
            @action="changePlate()"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import CarInfo from "@/components/UpdatedResume/CarInfo.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import { mapGetters } from "vuex";

import { formatNumber, translateString } from "@/functions/functions.js";
export default {
  name: "CarVersionSelect",
  components: {
    Button,
    CheckboxContainer,
    CarInfo,
    Footer,
    Header,
    Modal,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      selectedHorsepower: null,
      carName: this.$store.state.carName,
      carKms: formatNumber(this.$store.state.carKms, "."),
      carData: this.$store.state.carByPlate,
      carCombo: this.$store.state.carComboSelected,
      carListedInfo: [],
      carKtypesInfo: [],
      ktypeInfo: this.$store.state.carByPlate.ktypeInfo,
      carBrand: this.$store.state.carByPlate.brand
        ? this.$store.state.carByPlate.brand
        : this.$store.state.carNF.brand,
      showModel: false,
      selectedCarKtype: null,
      anyCheckboxSelected: false,
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    dealersReady() {
      return this.$store.state.dealersByBrandReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    ...mapGetters(["getCurrentStepIndex"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }

    if (
      this.$store.state.carByPlate.ktypes !== null &&
      this.$store.state.carByPlate.ktypes !== undefined
    ) {
      this.carKtypesInfo = this.$store.state.carByPlate.ktypeInfo.map(
        (item) => ({
          value: item.ktypnr,
          label:
            translateString("model") +
            ":" +
            " " +
            item.rangeNameClean +
            " " +
            translateString("version") +
            " " +
            item.typeName +
            " - Tipo: " +
            item.bodyType +
            " - " +
            translateString("horsePower") +
            " " +
            item.ps,
          condition: true,
          isChecked: false,
        })
      );
    }
    this.carListedInfo = [
      {
        label: translateString("plateDate"),
        value: this.carPlate,
        condition: this.carPlate ? true : false,
      },
      {
        label: translateString("model"),
        value:
          this.carData.brand && this.carData.model
            ? this.carData.brand + " " + this.carData.model
            : this.$store.state.carNF.brand +
              " " +
              this.$store.state.carNF.model,
        condition: true,
      },
      {
        label: translateString("version"),
        value: this.carData.typeModel
          ? this.carData.typeModel
          : this.$store.state.carNF.version,
        condition:
          this.carData.typeModel || this.$store.state.carNF.version
            ? true
            : false,
      },
      {
        label: translateString("gearType"),
        value: this.carData.gearbox,
        condition:
          this.carData.gearbox != "" &&
          this.carData.gearbox != "Nd" &&
          this.carData.gearbox
            ? true
            : false,
      },
      {
        label: translateString("brandModel"),
        value: this.carData.chassi
          ? this.carData.chassi
          : this.$store.state.carNF.body,
        condition:
          this.carData.chassi || this.$store.state.carNF.body ? true : false,
      },
      {
        label: translateString("doorsNumber"),
        value: this.carData.nDoors,
        condition: this.carData.nDoors ? true : false,
      },
      {
        label: translateString("fuel"),
        value: this.carData.fuel
          ? this.carData.fuel
          : this.$store.state.carNF.fuel,
        condition:
          this.carData.fuel || this.$store.state.carNF.fuel ? true : false,
      },
      {
        label: translateString("cc"),
        value: this.carData.cc + " cc",
        condition: this.carData.cc ? true : false,
      },
      {
        label: translateString("kms"),
        value: this.carKms + " kms",
        condition: this.carKms ? true : false,
      },
    ];
  },
  methods: {
    translateString,
    updateInput(value, index) {
      this.selectedCarKtype = value;
      this.selectCarKtypeInfo = value;
      this.$store.dispatch("selectCarKtype", value);
      this.$store.commit("SET_SELECTED_CAR_KTYPE", value);
      this.anyCheckboxSelected = true;
      this.selectedHorsepower =
        this.$store.state.carByPlate.ktypeInfo[index].ps;
      this.$store.commit(
        "SET_SELECTED_CAR_KTYPE_INFO",
        this.selectedHorsepower
      );
    },
    async next(id) {
      this.$store.dispatch("selectOption", {
        selectedValue: "isLoading",
        value: id
      });
      //verifico se this.dealersReady é verdadeiro. Se for, despacho várias ações e NÃO navega para a próxima página.
      if (this.dealersReady) {
        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
        this.$store.dispatch("selectCarKtype", this.selectedCarKtype.id);
        this.$store.dispatch("selectCarKtypeInfo", this.selectedHorsepower);
        this.$store.dispatch(
          "getCorrectToken",
          this.selectedCarKtype,
          this.$store.state.carPlate
        );
        //Se this.dealersReady for falso, navego para a página "CarConfirmation".
      } else {
        await this.$store.dispatch(
          "getCorrectToken",
          this.selectedCarKtype,
          this.$store.state.carPlate
        );
        await this.$store.dispatch(
          "getCarByToken",
          this.$store.state.carNF.token,
          this.$store.state.carNF.plate
        );
        if (this.$store.state.carCombo.length > 1) {
          //se tiver combo, ele entrará aqui
          this.$router.push({ name: "CarIdentification" });
        } else {
          //se só tiver um combo
          this.$store.dispatch("selectOption", {
            selectedValue: "carComboSelected",
            value: {
              comboLabel:
                this.$store.state.carCombo != ""
                  ? this.$store.state.carCombo[0].typeVehicleDesc
                  : "",
              comboId:
                this.$store.state.carCombo != ""
                  ? this.$store.state.carCombo[0].typeVehicleId
                  : 0,
              comboSelectedByUser: false,
            },
          });
          this.$router.push({ name: "CarConfirmation" });
        }
      }
      this.$store.dispatch("selectOption", {
        selectedValue: "isLoading",
        value: false
      });
    },

    changePlate() {
      this.$router.push({ name: "Homepage" });
    },
  },
  watch: {
    dealersReady() {
      if (this.dealersReady == true) {
        this.$router.push({ name: "CarConfirmation" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
